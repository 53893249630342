@import "src/variables";

.AboutPage {
  width: 100vw !important;
  background-color: white;
  background-image: linear-gradient(to top right, white, $clr_red);
  padding: 10vw 0;

  @media screen and (min-width: 800px) {
    width: unset !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .image-container {
    position: relative;
    display: flex;
    justify-content: center;

    img {
      max-width: 360px;
      max-height: 360px;
      min-width: 320px;
      min-height: 320px;
      width: 30vw;
      height: 30vw;
      margin: auto;
      border-radius: 50%;
      object-fit: cover;

      @media screen and (max-width: 400px) {
        max-width: 90vw;
        max-height: 90vw;
        min-width: 90vw;
        min-height: 90vw;
      }
    }

    .contact-button {
      position: absolute;
      bottom: 0;
      transform: translateX(130%);
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $clr_red_dark;
      border-radius: 50%;
      width: 5rem;
      height: 5rem;
      transition: .1s ease-in-out;
      border-bottom: unset;

      &:hover {
        background-color: darken($clr_red_dark, 8%);
      }

      p {
        color: white;
      }
    }
  }

  .list-container {
    margin-bottom: 1rem;

    p {
      margin-bottom: -0.3rem;
    }
  }

  h1 {
    margin: 1rem 0;
  }

  p {
    margin: 0 auto
  }
}
