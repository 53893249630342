$clr_black: #333;
$clr_white: #fff;
$clr_green: #9CC7C8;
$clr_green_dark: #5BA2A4;
$clr_blue: #93BED5;
$clr_blue_dark: #4791B8;
$clr_yellow_light: #f0f2d7;
$clr_yellow: #DDE2B5;
$clr_yellow_dark: #ABB847;
$clr_red: #E5B8B8;
$clr_red_dark: #bc544f;
