@import "../../variables";

.SchnapsContent {

  a {
    border-color: $clr_yellow_dark;

    &:hover {
      border-color: $clr_yellow;
    }
  }

  img {
    width: 100%;
    max-width: 100vw;
  }

  .header {
    max-width: 900px;
    width: 100%;
    margin: auto;

    .header-img-container {
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
        min-height: 100%;
        object-fit: cover;
        position: absolute;
      }

      .back {
        animation: fade-in 3s ease-in;

        @keyframes fade-in {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      }

      .front {
        transition: transform .3s ease-out;
        top: 0;
        left: 0;
        transform-origin: bottom center;
      }
    }

    .header-text-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(100% - 24px);
      max-width: 600px;
      transform: translateY(-3rem);
      margin: auto auto -2rem;
      padding: 1rem 24px;
      background-color: $clr_yellow_light;

      h1, h2 {
        margin: unset;
        padding: unset;
      }
    }
  }

  .body {
    max-width: 900px;
    margin: auto;

    .item-row {
      margin-top: 1rem;
      margin-bottom: 1rem;

      img {
        width: 80vw;
        max-width: 560px;
        display: flex;
        border-radius: 50%;
        margin: 1rem auto;
      }

      .text-container {
        margin: 0 24px;
        display: flex;
        flex-direction: column;

        h2, h3, p {
          margin: unset;
          padding: unset;
          width: fit-content;
        }

        h2 {
          background-color: $clr_yellow_light;
          width: fit-content;
          padding: .2rem .6rem;
          margin-bottom: 1rem;
        }

        p {
          padding: 0 .6rem;
          line-height: 1.6rem;
          @media screen and (max-width: 800px) {
            padding: unset;
          }
          &:first-of-type {
            line-height: 1.5rem;
            margin-bottom: 0.5rem;
          }
        }

        @media screen and (max-width: 800px) {
          margin-top: -2.5rem;
        }
      }

      &.un-even {
        h2, h3, p {
          align-self: flex-start;
          text-align: left;
        }
      }

      &.even {
        flex-direction: row-reverse;

        h2, h3, p {
          align-self: flex-end;
          text-align: right;
        }
      }
    }
  }

  .footer {
    position: relative;
    margin-top: 3rem;

    .order-container {
      padding: .5rem 0;
      margin-top: 4rem;
      margin-bottom: 4rem;
      background-color: $clr_yellow_light;

      h2 {
        margin: unset;
        padding: unset;
      }

      p {
        margin: auto;
      }

      @media screen and (min-width: 800px) {
        position: absolute;
        padding: 2rem 3rem;
        margin: unset;
        left: 50%;
        bottom: 20%;
        transform: translateX(-50%);
      }
    }

    img {
      object-position: top;
      object-fit: cover;
    }
  }
}
