$animDuration: .8s;
$breakPoint: 800px;

.DrawerNavContainer {
  display: flex;
  overflow: hidden;
  width: 100vw;
  max-width: 100vw;
  height: 100svh;

  .drawer-page-container {
    overflow: hidden;
    height: 100%;

    .inner-drawer-container {
      height: 100%;
      width: 100%;

      & > * {
        overflow-y: scroll;
        width: 100%;
        height: 100%;
      }
    }
  }

  .drawer-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: width $animDuration ease-in-out,
    height $animDuration ease-in-out,
    filter $animDuration * 1.5 ease-in-out;

    .drawer-label {
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1000;
      text-align: center;
      transform: rotate(180deg);
      writing-mode: vertical-rl;
      transition: min-width $animDuration ease-in-out,
      min-height $animDuration ease-in-out;

      .link {
        border-left: 2px solid transparent;
      }

      &:hover .link {
        border-left: 2px solid #333;

        @media screen and (max-width: $breakPoint) {
          border-left: unset;
        }
      }

      .link {
        margin: 0 4px 0 0;
        text-transform: uppercase;
        letter-spacing: 1rem;
        padding-top: 1rem;
        transition: font-size $animDuration ease-in-out;

        &.highlighted {
          font-size: 6vh;
        }
      }

      @media screen and (max-width: $breakPoint) {
        .link {
          font-size: 20px;
          border-left: unset;
          margin: unset;
          padding-top: 0.5rem;

          &.highlighted {
            font-size: 5vh;
          }
        }
      }
    }

    &.active {
      width: 100%;
    }

    &.not-active {
      width: 3rem;
    }
  }

  @media screen and (max-width: $breakPoint) {
    flex-direction: column;

    .drawer-item {
      flex-direction: column;

      .drawer-label {
        transform: unset;
        writing-mode: unset;
        min-height: 50px;

        h4 {
          padding-top: 0.5rem;
          margin: unset;
          border: unset;
        }
      }
    }
  }
}
