@import 'variables';

@font-face {
  font-family: 'Josefin Sans', sans-serif;
  src: url('/res/fonts/JosefinSans-Bold.ttf');
}
@font-face {
  font-family: Lato, sans-serif;
  src: url('/res/fonts/Lato-Regular.ttf')
}

html {

  // hack to prevent body from being scrolled
  &, body {
    height: 100%;
    overflow: hidden;
  }

  body {
    font-family: Lato, Avenir, sans-serif;
    margin: 0;
    padding: 0;

    h1, h2, h3, h4 {
      font-family: 'Josefin Sans', Avenir, sans-serif;
      font-weight: 500;
      text-align: center;
    }

    h1 {
      font-size: 5vmax;
      margin: 2em 0 1em;

      @media screen and (max-width: 600px) {
        font-size: 1.6rem;
      }
    }

    h2 {
      padding-bottom: 1.5rem;
      @media screen and (max-width: 600px) {
        font-size: 1.4rem;
      }
    }

    h3 {
      margin: 1.5rem 0 1rem;
      font-size: 1.2rem;
    }

    p {
      margin: 0 auto 0.8em;
      max-width: 500px;
      text-align: center;
      line-height: 1.9em;
    }

    a {
      color: $clr_black;
      padding-bottom: 0.1em;
      border-bottom: 0.2em solid white;
      text-decoration: none;
      transition: .1s ease-in-out;

      &:hover {
        color: $clr_red_dark;
        text-decoration: none;
      }
    }

    .button {
      cursor: grab;
      user-select: none;
    }

    .horizontal-container {
      display: flex;
      flex-direction: row;
    }

    .vertical-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
}

