@import "src/variables";

*, *:hover {
  cursor: none !important;
}

.RoundCursor {
  pointer-events: none;

  .cursor {
    position: absolute;
    content: '';
    z-index: 1000;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: $clr_red_dark;
    transform: translate(-50%, -50%);

    &.hide {
      opacity: 0;
    }
  }
}
