* {
  box-sizing: border-box;
}

.App {
  .Content {
    max-width: 900px;
    margin: auto;
    text-align: center;

    .padding-top {
      padding-top: 1.5em;
    }

    & > * {
      padding-bottom: 20vh;
    }
  }
}
